import React from 'react';
import {NavProps} from "./NavProps";
import {trackContactClicked} from "@renaissancerentals/renaissance-component";

export const HeaderNav: React.FC<HeaderNavProps> = (
    {
        setShowApplicationModal, setShowContactModal, modalClose,
        children
    }) => {

    return (
        <nav>
            <h3><a href="#apply" onClick={() => {
                modalClose();
                setShowApplicationModal(true);

            }}>apply</a></h3>
            {children}

            <h3><a href="#contact" onClick={() => {
                modalClose();
                setShowContactModal(true);
                trackContactClicked("bloomington-apartments");
            }}>contact</a></h3>
        </nav>
    )
};

export interface HeaderNavProps extends NavProps {
    modalClose: () => void;
}
