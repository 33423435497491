import React, {useRef, useState} from "react";
import {MainLayout} from "../components/layout/MainLayout";
import {Hero} from "../components/hero/Hero";
import {Floorplans} from "../components/floorplans/Floorplans";

export const Home: React.FC = () => {
    const [showContactModal, setShowContactModal] = useState(false);
    const [showApplicationModal, setShowApplicationModal] = useState(false);
    const floorPlanRef = useRef<HTMLDivElement>(null);

    const handleRefToSearch = () => {
        floorPlanRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "start"
        });
    };
    return (
        <MainLayout isMainPage={true}
                    showApplicationModal={showApplicationModal} showContactModal={showContactModal}
                    setShowApplicationModal={setShowApplicationModal}
                    setShowContactModal={setShowContactModal}
        >

            <Hero handleRefToSearch={handleRefToSearch}/>
            <div ref={floorPlanRef} id="floorplan" className="reference">
            </div>
            <div className="container">
                <h2>&nbsp;</h2>
            </div>
            <Floorplans/>

            <div className="container">
                <hr/>
            </div>
        </MainLayout>
    );
};
