import React from "react";
import {ApplicationModal, ContactModal, Footer, trackContactClicked} from "@renaissancerentals/renaissance-component";
import {MainHeader} from "../header/mainHeader/MainHeader";
import {FooterNav} from "../nav/FooterNav";
import {renaissance} from "../../data/RenaissanceData";

export const MainLayout: React.FC<MainLayoutProps> = (
    {
        isMainPage,
        setShowContactModal, setShowApplicationModal, showApplicationModal, showContactModal,
        children
    }) => {

    const handleRefToTop = () => {
        window.scrollTo(0, 0);
    };
    return (<>
            <ContactModal showContactModal={showContactModal}
                          contactModalCloseHandler={() => setShowContactModal(false)}
                          subject={"Message from bloomingtonapartmentsavailablenow.com"}
                          contactNumber={renaissance.contact.number}
                          propertyId="bloomington-apartments"
            />
            <ApplicationModal showApplicationModal={showApplicationModal}
                              applicationModalCloseHandler={() => setShowApplicationModal(false)}
                              contactClickHandler={() => {
                                  setShowApplicationModal(false);
                                  setShowContactModal(true);
                                  trackContactClicked("bloomington-apartments");
                              }}/>
            <MainHeader isMainPage={isMainPage} setShowApplicationModal={setShowApplicationModal}
                        handleRefToTop={handleRefToTop}
                        setShowContactModal={setShowContactModal}/>
            <main id="home">
                {children}
            </main>
            <Footer
                nav={<FooterNav setShowApplicationModal={setShowApplicationModal}
                                setShowContactModal={setShowContactModal}
                                isMainPage={isMainPage}/>}/>
        </>
    );

}

export interface MainLayoutProps {
    showContactModal: boolean;
    showApplicationModal: boolean;
    setShowApplicationModal: (show: boolean) => void;
    setShowContactModal: (show: boolean) => void;
    isMainPage: boolean;
    children?: any;
}
