import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {HelmetProvider} from 'react-helmet-async';
import {NotFound} from "./pages/NotFound";
import {Home} from "./pages/Home";
import "./assets/App.scss";
import HttpsRedirect from "react-https-redirect";
import {FloorplanPage} from "./pages/FloorplanPage";

const App: React.FC = () => {
    return (
        <HttpsRedirect>
            <HelmetProvider>
                <Router>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/floorplans/:floorplanId" element={<FloorplanPage/>}/>
                        <Route path="*" element={<NotFound/>}/>
                    </Routes>

                </Router>
            </HelmetProvider>
        </HttpsRedirect>
    );
};

export default App;
