import React from 'react';
import './assets/Hero.scss';
import {Button, Icon} from "@contentmunch/muncher-ui";

export const Hero: React.FC<HeroProps> = ({handleRefToSearch}) => {
    return (
        <section className="section-hero">
            <div className="hero-left">
                <div className="hero-search">
                    <header>
                        <div className="content">Are you moving soon?</div>
                    </header>
                    <p>We showcase apartments & homes for rent in Bloomington, Indiana that are ready for immediate
                        move-in</p>
                    <div className="hero-button">
                        <Button variant="primary" onClick={handleRefToSearch}>START YOUR SEARCH »</Button></div>
                </div>
            </div>
            <div className="hero-right">
                <div className="hero-right-content">
                    <header>Quality <Icon name="dot" size="medium"/> Variety <Icon name="dot" size="medium"/> Location
                    </header>
                    <blockquote>
                        Rent the Best<br/> in Bloomington
                    </blockquote>
                </div>
            </div>
        </section>
    );
}

export interface HeroProps {
    handleRefToSearch: () => void;
}
